
const ServiceData = [

  {
    id: "as1",
    icon: "fas fa-lightbulb",
    iconColor: "#8a55df",
    title: "Product Engineering",
    text:
      ["E2E Application Development", "Quality Assurance Engineering", "Application Modernization", "Software Integration"],
    to: "services/product-engineering"
  },

  {
    id: "as2",
    icon: "fas fa-chart-pie",
    iconColor: "#8a55df",
    title: "Solution Development/ POC",
    text:
      ["MVP Development (Mobile/Web)", "Blockchain Application development", "Visual Design/ UI UX", "Proof of concepts", "Research and Development"],
    to: "services/solution-development"
  },
  {
    id: "as3",
    icon: "fas fa-cloud",
    iconColor: "#8a55df",
    title: "Cloud Enablement",
    text:
      [" Cloud Enablement  ", "Cloud Migration  ", "Cloud Security  ", "Provision Management", "Database Administration", "Site Reliability Engineering"],
    to: "services/clouds-enablement"
  },
  {
    id: "as4",
    icon: "fas fa-cogs",
    iconColor: "#8a55df",
    title: "Enterprise Application Engineering",
    text:
      ["New product Development", "E2E Application Services", "Prototype Development", "Support / Maintainace"],
    to: "services/enterprise-applications"
  },

  {
    id: "as5",
    icon: "fas fa-user",
    iconColor: "#8a55df",
    title: "Outsourcing",
    text:
      ["Software Engineers", "Quality Assurance Engineers", "Devops Engineers"],
    to: "services/out-sourcing"
  },
]
export default ServiceData;




// {
//   id: "as5",
//   icon: "fas fa-layer-group",
//   iconColor: "#6001d3",
//   title: "Digital Agency",
//   text:
//     "Stay on top of your task lists and stay in touch with what's happening",
// },
