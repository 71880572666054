import { Link } from '~components'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images } from '~data'
import Footer from "./style"
export default function FooterFive() {
    return (
        <Footer backgroundColor="#f3f4f6">
            <Container>
                <Footer.Box pb="25px">
                    <Row className="justify-content-center justify-content-lg-between">
                        <Col xs="12">
                            <Footer.Logo>
                                {/* Brand Logo*/}
                                <Footer.Box mb="30px">
                                    <Link to="#">
                                        <img src={Images.FooterSix.LogoBlack} alt="logo" />
                                    </Link>
                                </Footer.Box>
                            </Footer.Logo>

                            <Footer.Text as="p">Bring your idea into finger tips.</Footer.Text>
                            <Footer.Menu>
                                <Footer.MenuItems><a href="/">Home</a></Footer.MenuItems>
                                {/* <Footer.MenuItems> <a href="/about-us">About Us</a> </Footer.MenuItems> */}
                                {/* <Footer.MenuItems><a href="services">Services</a></Footer.MenuItems> */}
                                <Footer.MenuItems><a href="/portfolio">Portfolio</a></Footer.MenuItems>
                                <Footer.MenuItems><a href="/career">Career</a></Footer.MenuItems>
                                <Footer.MenuItems><a href="/contact">Contact Us</a>
                                </Footer.MenuItems>
                            </Footer.Menu>
                        </Col>
                    </Row>
                </Footer.Box>
                <Footer.Copyright>
                    <Footer.SocialShare>
                        <Footer.SocialShareItem>
                            <a href="https://www.linkedin.com/company/axcer/">
                                <i className="fab fa-linkedin" />
                            </a>
                        </Footer.SocialShareItem>

                        <Footer.SocialShareItem>
                            <a href="https://www.facebook.com/AxcerInnovations">
                                <i className="fab fa-facebook-square" />
                            </a>
                        </Footer.SocialShareItem>
                        {/* 
                        <Footer.SocialShareItem>
                            <a href="#">
                                <i className="fab fa-twitter" />
                            </a>
                        </Footer.SocialShareItem> */}
                        <Footer.SocialShareItem>
                            <a href="https://github.com/Axcer-io">
                                <i className="fab fa-github" />
                            </a>
                        </Footer.SocialShareItem>

                        <Footer.SocialShareItem>
                            <a href="https://www.instagram.com/axcer.io/">
                                <i className="fab fa-instagram" />
                            </a>
                        </Footer.SocialShareItem>



                    </Footer.SocialShare>
                    <Footer.CopyrightText>© 2023 Axcer Innovations, All Rights Reserved</Footer.CopyrightText>
                </Footer.Copyright>
            </Container>
        </Footer>
    )
}