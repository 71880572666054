import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ServicesCard from './Components/Card'
import ServiceData from "~data/agency/Service"
import SectionTitle from './Components/SectionTitle'
import Service from './style'
export default function ServiceSection() {
  return (
    <Service backgroundColor="#f3f4f6">
      <Container>
        <Row className="row justify-content-center">
          <Col className="col-lg-9 col-xl-9 col-xxl-7 text-center">
            <SectionTitle
              subTitle="Our Services"
              title="Providing solutions of every kind"

              subTitleProps={{ mb: "27px" }}
              titleProps={{ mb: "10px", as: "h2" }}
              mb="20px" />
          </Col>
        </Row>
        <Row className="row justify-content-center">

          {ServiceData.map(({ title, icon, text, id, iconColor, to }) => {
            return (
              <Col
                xs="12"
                className="col-xl-4 col-lg-6 col-md-6 col-sm-9 col-xs-10"
                key={id}
              >
                <ServicesCard
                  title={title}
                  text={text}
                  icon={icon}
                  to={to}
                  iconBackground={iconColor}
                  key={id}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
    </Service>

  )
}